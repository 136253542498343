/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query BlogSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="sticky top-0 z-50 bg-white">
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} beta />
      </div>
      <div
        style={{
          margin: `0 auto`,
          padding: `32px`,
          height: "100vh",
        }}
      >
        <main className="w-full md:flex items-center flex-col pb-8">
          <div className="prose lg:prose-xl prose-ul:max-w-xl">{children}</div>
        </main>
        <footer
          style={{
            marginTop: `var(--space-5)`,
            fontSize: `var(--font-sm)`,
          }}
        >
          {/* © {new Date().getFullYear()} */}
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
