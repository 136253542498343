import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog_layout.js";
import YouTube from "react-youtube";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "intro-to-metaprogramming-for-r"
    }}><a parentName="h1" {...{
        "href": "#intro-to-metaprogramming-for-r"
      }}>{`Intro to Metaprogramming for R`}</a></h1>
    <p>{`Metaprogramming is a generally useful tool to solve certain kinds of programming
and data analysis problems. `}<strong parentName="p">{`This talk will cover some important applications of
metaprogramming to dplyr and highlight how R's metaprogramming makes working with
tibbles and dplyr more ergonomic than working with pandas data frames.`}</strong></p>
    <p>{`We'll also get a high-level overview of the important APIs used for R
metaprogramming and work through an example metaprogramming function together.`}</p>
    <YouTube videoId="BXv0iVOiCrw" mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      